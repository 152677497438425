import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { getJewishDate } from 'jewish-dates-core';
import { formatJewishDateInHebrew } from 'jewish-date';
import './dedication.scss';
import { fetchData } from '../../utils/http';

export default function Dedication() {
	const [dedication, setDedication] = useState('');
	useEffect(() => {
		const date = formatJewishDateInHebrew(getJewishDate(new Date()));
		fetchData(`dedication/get-dedication?date=${date}`).then(res => {
			setDedication(res.data.text);
		});
	}, []);


	return (
		<>
			{dedication && <Box className="dedication">
				<Typography variant="h4" mb={1}>הלימוד היומי מוקדש</Typography>
				<Box dangerouslySetInnerHTML={{ __html: dedication }}></Box>
			</Box>}
		</>
	);
}
