import React, { useState, useMemo } from 'react';

import Header from '../header/Header';

import './layout.scss';
import RouterConfig from '../../routing/RouterConfig';
import Footer from '../footer/Footer';
import Loader from '../loader/Loader';
import { LoaderContext } from '../../utils/contexts';

export default function Layout() {
	const [loader, setLoader] = useState(false);
	const [uploadPercentage, setUploadPercentage] = useState(0);
	const loaderValue = useMemo(
		() => ({ loader, setLoader }), 
		[loader]
	);
	const uploadPercentageValue = useMemo(
		() => ({ uploadPercentage, setUploadPercentage }), 
		[uploadPercentage]
	);


	return (
		<LoaderContext.Provider value={{ loaderC: loaderValue, uploadPercentageC: uploadPercentageValue}}>
			<Header />
			<RouterConfig />
			<Footer />
			<Loader />
		</LoaderContext.Provider>
	);
}