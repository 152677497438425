import axios from 'axios';
import { getLocalStorage } from './localStorage';

const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL, // eslint-disable-line no-undef
});

api.interceptors.request.use(
	request => {
		const token = getLocalStorage('token');
		request.headers['Authorization'] = `Bearer ${token}`;
		return request;
	},
	error => {
		return Promise.reject(error);
	}
);

export async function fetchData (path) {
	return api.get(path);
}

export async function postData (path, _data, isFormData = false, onUploadProgress) {
	const data = isFormData ? _data : {..._data};
	return api.post(path, data, {onUploadProgress: onUploadProgress});
}