import React, { useContext } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { LoaderContext } from '../../utils/contexts';

import './loader.scss';


export default function Loader(props) {

	const { loaderC, uploadPercentageC } = useContext(LoaderContext);
	const { loader } = loaderC;
	const { uploadPercentage } = uploadPercentageC;
	return (
		<Box>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={loader}
			>
				{/* {uploadPercentage}%
				<CircularProgress color="inherit" variant='determinate' value={uploadPercentage} /> */}
				{/* <Box sx={{ position: 'relative', display: 'inline-flex' }}>
					<CircularProgress color="inherit" variant="determinate" value={uploadPercentage} />
					<Box
						sx={{
							top: 0,
							left: 0,
							bottom: 0,
							right: 0,
							position: 'absolute',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Typography variant="caption" component="div" color="#fff">
							{`${uploadPercentage}%`}
						</Typography>
					</Box>
				</Box> */}
			</Backdrop>
		</Box>
	);
}
