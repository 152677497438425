const TRACK_DATA = {
	one: {
		desc: 'פרק אחד',
		param: 'one',
	},
	three: {
		desc: 'שלושה פרקים',
		param: 'three',
	},
	sefer: {
		desc: 'ספר המצוות',
		param: 'sefer',
	},
};

export { TRACK_DATA };