/* eslint-disable no-useless-escape */
export const validEmailRegex = RegExp(
	/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

export const validFullNameRegex = RegExp(
	/([\u0590-\u05fe]{1,})+\s+([\u0590-\u05fe]{1,})+[\u0590-\u05fe\s]*/
);

export const validateForm = (errors) => {
	let valid = true;
	Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
	return valid;
};