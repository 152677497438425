import { getLocalStorage } from './localStorage';

const isLecturer = () => {
	return ['lecturer', 'admin', 'developer'].includes(getLocalStorage('role'));
};

const isDeveloper = () => {
	return getLocalStorage('role') === 'developer';
};

const isAdmin = () => {
	return ['admin', 'developer'].includes(getLocalStorage('role'));
};

const isExpired = () => {
	const expires = getLocalStorage('expiresIn');
	return expires < new Date().getTime();
};

export {isDeveloper, isLecturer, isAdmin, isExpired };



