import React from 'react';
import { Link } from 'react-router-dom';

import VideocamIcon from '@mui/icons-material/Videocam';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import './classCard.scss';

export default function ClassCard(props) {
	return (
		<Card
			sx={{
				margin: 'auto',
				textAlign: 'center',
			}}
			className="class-card"
		>
			<Link to={(window.location.search.includes('previous') ? '..' : '') + `/class/${props.class_id}/${props.classNum}/${props.track}`}>
				<CardActionArea>
					<Box className="avatar-container">
						<Avatar className="avatar" alt="" src={props.avatar} />
						{props.media_type === 'audio' ? (
							<VolumeDownIcon className="media-icon" />
						) : (
							<VideocamIcon className="media-icon" />
						)}
					</Box>

					<CardContent className='card-content'>
						<Typography
							gutterBottom
							variant="h5"
							component="div"
							className="lecturer-name font-alef"
						>
              הרב {props.full_name}
						</Typography>
					</CardContent>
					<Box className="class-metadata">
						<Box>{props.duration}</Box>|
						<Box>{!props.additional_info ? 'מלא' : props.additional_info}</Box>|
						<Box>{props.language === 'hebrew' && 'עברית'}</Box>
					</Box>
				</CardActionArea>
			</Link>
		</Card>
	);
}
