function setLocalStorage(dataObj) {
	for (let [key, value] of Object.entries(dataObj)) {
		localStorage.setItem(key, JSON.stringify(value));
	}
}

function getLocalStorage(key) {
	const data = localStorage.getItem(key) || sessionStorage.getItem(key);
	if (data) {
		return JSON.parse(data);
	}
	return null;
}

function clearLocalStorage() {
	localStorage.clear();
}

export { setLocalStorage, getLocalStorage, clearLocalStorage };