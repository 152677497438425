import React, { useState } from 'react';

import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';

import './header.scss';
import MainMenu from '../main-menu/MainMenu';
import logo from '../../assets/logo1.webp';
import logoMini from '../../assets/logo1_256x122.webp';
import { Link as RouterLink } from 'react-router-dom';

export default function Header() {
	const [showAlert, setShowAlert] = useState(true);
	return (
		<Grid container className="header">
			<Grid item xs={12} className="bsd">
        בס"ד
			</Grid>
			<Grid item xs={12}>
				{showAlert && <Alert className='alert' variant='outlined' severity="warning" onClose={() => setShowAlert(false)}>
			האתר בתקופת הרצה. מצאתם שגיאות?
			&nbsp;
					<Link href='mailto:rambam.haazinu@gmail.com?subject=' target='_blank'>עדכנו אותנו </Link>
				</Alert>}
			</Grid>
			<Grid item xs={3}>
				<MainMenu />
			</Grid>
			<Grid item xs={6} className="logo-text">
				<RouterLink to={'/'}>
					<img src={logo}
						srcSet={logoMini}
						className="pointer"
						alt="logo" />
				</RouterLink>
			</Grid>
		</Grid>
	);
}
