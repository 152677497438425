import React from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import './myModal.scss';

export default function MyModal(props) {
	return (
		<Modal
			open={props.open}
			onClose={props.handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box className="my-modal">
				{props.children}
			</Box>
		</Modal>
	);
}