import React, { useState, useContext } from 'react';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';

import MyModal from '../my-modal/MyModal';
import { LoaderContext } from '../../utils/contexts';
import { postData } from '../../utils/http';
import { validateForm } from '../../utils/validation';

import './createNewClass.scss';

const classTemplate = {
	media_type: '',
	language: '',
	track: '',
	additional_info: 'מלא',
};

export default function CreateNewClass(props) {
	const [open, setOpen] = useState(false);
	const [submited, setSubmited] = useState(false);
	const { loaderC } = useContext(LoaderContext);
	const { setLoader } = loaderC;
	const [newClass, setNewClass] = useState({
		...classTemplate,
		lecturer_id: props.lecturerId,
	});
	
	const [errors, setErrors] = useState({
		track: 'בחר מסלול',
		media: 'בחר סוג מדיה',
		lang: 'בחר שפה',
	});
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		switch (name) {
		case 'track':
			setNewClass((state) => ({ ...state, track: value }));
			setErrors({
				...errors,
				track: value.length > 0 ? '' : 'בחר מסלול',
			});
			break;
		case 'media':
			setNewClass((state) => ({ ...state, media_type: value }));
			setErrors({
				...errors,
				media: value.length > 0 ? '' : 'בחר סוג מדיה',
			});
			break;
		case 'language':
			setNewClass((state) => ({ ...state, language: value }));
			setErrors({
				...errors,
				lang: value.length > 0 ? '' : 'בחר שפה',
			});
			break;
		case 'additional_info':
			setNewClass((state) => ({ ...state, additional_info: value }));
			break;
		default:
			break;
		}
	};

	const submitClass = (e) => {
		e.preventDefault();
		setSubmited(true);
		if (validateForm(errors)) {
			setLoader(true);
			postData('class/add-class', newClass)
				.then((res) => {
					props.getEndSetClasses();
					setLoader(false);
					window.alert('השיעור נוסף בהצלחה');
					setNewClass({
						...classTemplate,
						lecturer_id: props.lecturerId,
					});
				})
				.catch((err) => {
					setLoader(false);
					window.alert(err.response.data);
				});
			handleClose();
		}
	};

	return (
		<>
		<Button
				sx={{ position: 'fixed', bottom: 50, right: 30 }}
				endIcon={<SpeedDialIcon />}
				color="primary"
				variant="contained"
				onClick={handleOpen}
			>
        צור שיעור
			</Button>
			<MyModal open={open} handleOpen={handleOpen} handleClose={handleClose}>
				<Box
					className="new-class-form"
					component="form"
					sx={{
						'& .MuiTextField-root': { m: 1, width: '35ch' },
					}}
					noValidate
					autoComplete="off"
					onSubmit={submitClass}
				>
					<Box textAlign="center" marginBottom={2}>
						<Typography variant="h6">צור שיעור חדש</Typography>
					</Box>
					<Box marginBottom={2}>
						<FormControl fullWidth>
							<InputLabel variant="standard" htmlFor="trake">
                בחר מסלול
							</InputLabel>
							<NativeSelect
								value={newClass.track}
								inputProps={{
									name: 'track',
								}}
								onChange={handleChange}
								error={submited && errors.track}
							>
								<option value=""></option>
								<option value="three">שלושה פרקים</option>
								<option value="one">פרק אחד</option>
								<option value="sefer">ספר המצוות</option>
							</NativeSelect>
						</FormControl>
						{submited && errors.track && (
							<Typography className="input-err">{errors.track}</Typography>
						)}
					</Box>
					<Box marginBottom={2}>
						<FormControl fullWidth>
							<InputLabel variant="standard" htmlFor="media">
                בחר סוג מדיה
							</InputLabel>
							<NativeSelect
								value={newClass.media_type}
								inputProps={{
									name: 'media',
								}}
								onChange={handleChange}
								error={submited && errors.media}
							>
								<option value=""></option>
								<option value="audio">אודיו</option>
								<option value="video">וידאו</option>
							</NativeSelect>
						</FormControl>
					</Box>
					<Box marginBottom={2}>
						<FormControl fullWidth>
							<InputLabel variant="standard" htmlFor="trake">
                בחר שפה
							</InputLabel>
							<NativeSelect
								value={newClass.language}
								inputProps={{
									name: 'language',
								}}
								onChange={handleChange}
								error={submited && errors.lang}
							>
								<option value=""></option>
								<option value="hebrew">עברית</option>
								<option value="yidish">אידיש</option>
								<option value="english">אנגלית</option>
							</NativeSelect>
						</FormControl>
					</Box>
					<Box>
						<TextField
							fullWidth
							name="additional_info"
							label='סגנון שיעור'
							variant="outlined"
							type="text"
							onChange={handleChange}
							value={newClass.additional_info}
						/>
					</Box>
					<Box textAlign="center">
						<Button
							className="submit-btn"
							type="submit"
							variant="contained"
							color="primary"
						>
              צור
						</Button>
					</Box>
				</Box>
			</MyModal></>
	);
}
