import React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import './basicClassCard.scss';

const HEBREW = {
	'three': 'שלושה פרקים',
	'one': 'פרק אחד',
	'sefer': 'ספר המצוות',
	'audio': 'אודיו',
	'video': 'וידאו',
	'hebrew': 'עברית',
	'english': 'אנגלית',
	'yidish': 'אידיש',
};

export default function BasicClassCard(props) {


	return (
		<Card
			sx={{
				width: 200,
				margin: 'auto',
				textAlign: 'center',
			}}
			className={ 'basic-class-card ' + (props.currId === props.id ? 'active' : '')}
		>
			<CardHeader
				
				action={
					<IconButton color="secondary" onClick={() => props.deleteClass(props.id)}>
						<DeleteOutlineIcon />
					</IconButton>
				}
				title={HEBREW[props.track]}
				className="card-header"
			/>

			<CardActionArea onClick={() => props.setCurrClass(props)}>
				<CardContent className="card-content">
					<Typography
						gutterBottom
						variant="h6"
						component="div"
						className="class-data font-alef"
					>
						{HEBREW[props.language]} | {HEBREW[props.media_type]}
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}
