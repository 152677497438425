import React, { useEffect, useState } from 'react';
import {
	Box,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Keyboard } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import ClassCard from '../class-card/ClassCard';
import TrackPicker from '../track-picker/TrackPicker';
import DatePicker from '../date-picker/DatePicker';
import './classesSection.scss';
import { fetchData } from '../../utils/http';

export default function ClassesSection(props) {
	const [lecturers, setLecturers] = useState([]);
	const [dailyChapters, setDailyChapters] = useState('');
	const [track, setTrack] = useState({
		desc: 'שלושה פרקים',
		param: 'three',
	});
	const [date, setDate] = useState(new Date());
	const [classNum, setClassNum] = useState(0);
	const [spinner, setSpinner] = useState(false);

	useEffect(() => {
		setSpinner(true);
		const formattedDate = date.toLocaleDateString('en-CA', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit'
		});
		fetchData(`class/get-lecturers?track=${track.param}&date=${formattedDate}`)
			.then((res) => {
				setLecturers(res.data.lecturers);
				setClassNum(res.data.classNum);
				setSpinner(false);
			})
			.catch((err) => {
				setSpinner(false);
			});
		getAndSetDailyChapters();
	}, [track, date]);
		
	const getAndSetDailyChapters = () => {
		const formattedDate = date.toLocaleDateString('en-CA', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit'
		});
		fetchData(`class/get-daily-chapters?track=${track.param}&date=${formattedDate}`).then(res => {
			setDailyChapters(res.data);
		}).catch(err => {
			console.log(err);
		});
	};

	const lecturerList = lecturers.map((lec) => (
		<SwiperSlide dir="ltr" key={lec.class_id}>
			<ClassCard {...lec} classNum={classNum} track={track?.param} />
		</SwiperSlide>
	));

	return (
		<Box className="classes-section">
			<TrackPicker setTrack={setTrack} track={track} />
			<Box className="date-picker-container">
				<DatePicker date={date} setDate={setDate} navigation/>
				<Box className="daily-chapters">{dailyChapters}</Box>
				<Box className="container carousel-wraper">
					{spinner && <Box className="spinner">
						<CircularProgress color='secondary'/>
					</Box>}
					<Swiper
						slidesPerView={1}
						spaceBetween={3}
						centerInsufficientSlides={true}
						keyboard={true}
						navigation={{enabled: true}}
						breakpoints={{
							350: {
								slidesPerView: 2,
							},
							640: {
								slidesPerView: 3,
							},
							850: {
								slidesPerView: 4,
							},
							1024: {
								slidesPerView: 5,
							},
						}}
						dir='rtl'
						pagination={{
							clickable: true,
						}}
						style={{
							'--swiper-pagination-color': '#fff',
							'--swiper-navigation-color': '#fff',
						}}
						modules={[Navigation, Pagination, Keyboard]}
						className="mySwiper"
					>
						{spinner || lecturerList.length > 0 ? lecturerList :
							<Box className='no-lessons'>טרם הועלו שיעורים ליום הנבחר</Box>
						}
					</Swiper>
				</Box>
			</Box>
		</Box>
	);
}
