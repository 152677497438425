import React, { useState } from 'react';

import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { GiBookshelf, GiShakingHands } from 'react-icons/gi';

import './mainMenu.scss';
import { Link } from 'react-router-dom';
import { isAdmin, isLecturer } from '../../utils/auth';
import { getLocalStorage } from '../../utils/localStorage';

const menuItems = [
	{ text: 'דף הבית', icon: HomeIcon, link: '/' },
	{ text: 'אודותינו', icon: InfoIcon, link: '/about' },
	// { text: 'שאל את הרב', icon: ContactSupportIcon, link: '' },
	{ text: 'שיעורים קודמים', icon: GiBookshelf, link: '/previous' },
	// { text: 'היו שותפים', icon: GiShakingHands, link: '' },
];

export default function MainMenu() {
	const [open, setOpen] = useState(false);

	const toggleDrawer = (open) => (event) => {
		if (
			event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}

		setOpen(open);
	};

	const list = () => (
		<Box
			className="menu-list"
			sx={{ width: 320 }}
			onClick={toggleDrawer(false)}
			onKeyDown={toggleDrawer(false)}
		>
			<List>
				{menuItems.map((item) => (
					<Link to={item.link} key={item.text}>
						<ListItemButton className="menu-list-item center">
							<ListItem disablePadding>
								<ListItemText primary={item.text} className="menu-item-text" />
								<ListItemIcon className="menu-item-icon">
									<item.icon className="menu-icon" />
								</ListItemIcon>
							</ListItem>
						</ListItemButton>
					</Link>
				))}
				{isAdmin() && <Link to="/admin">
					<ListItemButton className="menu-list-item center">
						<ListItem disablePadding>
							<ListItemText primary="ניהול" className="menu-item-text" />
							<ListItemIcon className="menu-item-icon">
								<ManageAccountsIcon className="menu-icon" />
							</ListItemIcon>
						</ListItem>
					</ListItemButton>
				</Link>}
				{isLecturer() && <Link to={`/user/${getLocalStorage('id')}`}>
					<ListItemButton className="menu-list-item center">
						<ListItem disablePadding>
							<ListItemText primary="אזור אישי" className="menu-item-text" />
							<ListItemIcon className="menu-item-icon">
								<AccountCircleIcon className="menu-icon" />
							</ListItemIcon>
						</ListItem>
					</ListItemButton>
				</Link>}
				<a href="https://api.whatsapp.com/send?phone=972587554144&text=%D7%9E%D7%A2%D7%95%D7%A0%D7%99%D7%99%D7%9F%20%D7%9C%D7%94%D7%A6%D7%98%D7%A8%D7%A3%20%D7%9C%D7%A9%D7%99%D7%A2%D7%95%D7%A8%20%D7%A8%D7%9E%D7%91%22%D7%9D%20%D7%99%D7%95%D7%9E%D7%99" target="_blank" rel="noreferrer">
					<Button disableRipple color="secondary" className="whatsapp-btn">
						<WhatsAppIcon className="whatsapp-icon" />
						<Typography className="whatsapp-text">
            לקבלת השיעורים<br />
             לנייד בוואטסאפ
						</Typography>
					</Button>
				</a>
			</List>
		</Box>
	);

	return (
		<Box className="main-menu">
			<Button onClick={toggleDrawer(true)} className="menu-btn">
				<MenuIcon color="primary" className="menu-icon pointer" />
			</Button>

			<SwipeableDrawer
				anchor="left"
				open={open}
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}
				className="drawer"
			>
				<Button
					disableRipple
					color="secondary"
					className="close-btn"
					onClick={toggleDrawer(false)}
				>
					<CloseIcon />
				</Button>
				{list()}
			</SwipeableDrawer>
		</Box>
	);
}