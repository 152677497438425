import React from 'react';
import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';


import Dedication from '../dedication/Dedication';
import './footer.scss';

const PATHNAMES = [
	'class',
];

export default function Footer() {
	const location = useLocation();

	function showDedication() {
		const path = location.pathname;
		return path === '/' || PATHNAMES.some(p => path.startsWith('/' + p));
	}

	return (
		<Box>
			{showDedication() && <Dedication />}
		</Box>
	);
}
