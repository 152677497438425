import React, { createContext } from 'react';

const LoaderContext = createContext({
	loader: false,
	setLoader: () => {},
	uploadPercentage: 0,
	setUploadPercentage: () => {},
});


export { LoaderContext };



