import React from 'react';

import { Box, Button } from '@mui/material';
import {
	addDates,
	subtractDates,
} from 'jewish-dates-core';
import { ReactJewishDatePicker } from 'react-jewish-datepicker';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import 'react-jewish-datepicker/dist/index.css';
import './datePicker.scss';

const HEB_WEEK_DAYS = [
	'יום ראשון',
	'יום שני',
	'יום שלישי',
	'יום רביעי',
	'יום חמישי',
	'יום שישי',
	'שבת קודש',
];

export default function DatePicker(props) {
	const handleClick = (e) => {
		const { id } = e.target.dataset;
		if (id === 'prev') {
			props.setDate(subtractDates(props.date, 1));
		} else {
			props.setDate(addDates(props.date, 1));
		}
	};
	return (
		<Box className="date-picker">
			{props.navigation && <Button className='nav-btn' data-id="prev" variant="contained" onClick={handleClick}>
				<NavigateNextIcon data-id="prev" />
			</Button>}
			<ReactJewishDatePicker
				className="date-display"
				value={props.date}
				isHebrew
				onClick={(day) => {
					props.setDate(new Date(day.date));
				}}
			/>
			{props.navigation && <Button className='nav-btn' data-id="next" variant="contained" onClick={handleClick}>
				<NavigateBeforeIcon data-id="next" />
			</Button>}
		</Box>
	);
}
