import React, { useEffect, useRef, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import './home.scss';
import ClassesSection from '../classes-section/ClassesSection';
import LecturerContact from '../lecturer-contact/LecturerContact';

// import Dedication from '../dedication/Dedication';

export default function Home() {
	// const installButton = useRef(null);
	// const [installPrompt, setInstallPrompt] = useState(null);

	// useEffect(() => {
	// 	window.addEventListener('beforeinstallprompt', (event) => {
	// 		event.preventDefault();
	// 		console.log(event);
	// 		setInstallPrompt(event);
	// 	});
	// 	installButton.current.addEventListener('click', async () => {
	// 		console.log(installPrompt);
	// 		if (!installPrompt) {
	// 			return;
	// 		}
	// 		const result = await installPrompt.prompt();
	// 		console.log(`Install prompt was: ${result.outcome}`);
	// 		// disableInAppInstallPrompt();
	// 	});
	// }, []);


	return (
		<Box className="home">
			<Box className="bg">
				<Box className="main-heading">
					<Typography className="heading-part-1">לימוד</Typography>
					<Typography className="heading-part-2 font-secular">התורה כולה</Typography>
					<Typography className="heading-part-3">
            במסלולי הרמב"ם היומי
					</Typography>
				</Box>
				<ClassesSection />
				<LecturerContact />
				{/* <Dedication /> */}
				{/* <Box className='center-text' p={2}>
					<IconButton ref={installButton} >

						<InstallMobileIcon color='secondary' />
					</IconButton>
				</Box> */}
			</Box>
		</Box>
	);
}
