import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { isAdmin, isExpired, isLecturer } from '../utils/auth';

const ProtectedRouteAdmin = () => {
	if (!isAdmin()) {
		return <Navigate to="/" replace />;
	} else if (isExpired()) {
		return <Navigate to="/login" replace />;
	}
	return <Outlet/>;
};

const ProtectedRouteLecturer = () => {
	if (!isLecturer()) {
		return <Navigate to="/" replace />;
	} else if (isExpired()) {
		return <Navigate to="/login" replace />;
	}
	return <Outlet/>;
};

export { ProtectedRouteAdmin, ProtectedRouteLecturer };
