import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRouteAdmin, ProtectedRouteLecturer } from './ProtectedRoute';

// import About from '../components/about/About';
// import Admin from '../components/admin/Admin';
// import Class from '../components/class/Class';
import Home from '../components/home/Home';
// import Login from '../components/login/Login';
// import Register from '../components/register/Register';
// import UploadMetaData from '../components/upload-meta-data/UploadMetaData';
// import User from '../components/user/User';
// import Users from '../components/users/Users';
// import Dedications from '../components/dedications/Dedications';
// import Previous from '../components/previous/Previous';
import useGaTracker from '../utils/UseGATracker';
import UserProfile from '../components/user-profile/UserProfile';
import UploadClasses from '../components/upload-classes/UploadClasses';
const About = lazy(() => import('../components/about/About'));
const Admin = lazy(() => import('../components/admin/Admin'));
const Class = lazy(() => import('../components/class/Class'));
// const Home = lazy(() => import('../components/home/Home'));
const Login = lazy(() => import('../components/login/Login'));
const Register = lazy(() => import('../components/register/Register'));
const UploadMetaData = lazy(() => import('../components/upload-meta-data/UploadMetaData'));
const User = lazy(() => import('../components/user/User'));
const Users = lazy(() => import('../components/users/Users'));
const Dedications = lazy(() => import('../components/dedications/Dedications'));
const Previous = lazy(() => import('../components/previous/Previous'));
export default function RouterConfig() {
	useGaTracker();

	return (
		<Suspense>
			<Routes>
				<Route exact path="/" element={<Home />} />
				<Route path="/login" element={<Login />} />
				<Route path="/register" element={<Register />} />
				<Route path="/class/:classId/:classNum/:track" element={<Class />} />
				<Route path="/about" element={<About />} />
				<Route path="/previous" element={<Previous />} />
				<Route element={<ProtectedRouteAdmin />}>
					<Route path="/admin" element={<Admin />}>
						<Route index element={<Users />} />
						<Route path="upload-meta-data" element={<UploadMetaData />} />
						<Route path="dedications" element={<Dedications />} />
					</Route>
				</Route>
				<Route element={<ProtectedRouteLecturer />}>
					<Route path="/user/:userId" element={<User />}>
						<Route index element={<UserProfile />} />
						<Route path='upload-classes' element={<UploadClasses />} />
					</Route>
				</Route>
			</Routes>
		</Suspense>
	);
}
